import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { withPreview } from '@prismicio/gatsby-source-prismic-graphql';

const query = graphql`
  query {
    prismic {
      allNavigations {
        edges {
          node {
            _meta {
              uid
              tags
            }
            body {
              ... on PRISMIC_NavigationBodySocial_network {
                fields {
                  social_network_url {
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                  social_network
                }
              }
            }
            company_display_name
            company_address
            company_phone
            footer_links {
              link_label
              link {
                _linkType
                ... on PRISMIC_Content_page {
                  _meta {
                    uid
                    type
                  }
                }
                ... on PRISMIC_Contact_page {
                  _meta {
                    uid
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;


export const Signup = () => (
  <StaticQuery
    query={query}
    render={withPreview(data => {
      return (
        <section className="block-lg text-center bg-gray mt-3" role="contentinfo">
          <div className="container">
            <h3 className="mt-0 brand-font">Sign Up to get exclusive updates from Braun Auctions</h3>
            <p>There's a host of reasons why you should sign up to get updates from Braun Auctions</p>
            <form method="POST" action="https://braunauctions.activehosted.com/proc.php" id="_form_1_" className="_form _form_1 _inline-form _dark mt-2 row" noValidate>
              <input type="hidden" name="u" value="1" />
              <input type="hidden" name="f" value="1" />
              <input type="hidden" name="s" />
              <input type="hidden" name="c" value="0" />
              <input type="hidden" name="m" value="0" />
              <input type="hidden" name="act" value="sub" />
              <input type="hidden" name="v" value="2" />
              <div className="_form-content">
                <div className="_form_element _x92792987 _full_width form-group form-group-lg col-sm-5">
                  <label className="sr-only _form-label" htmlFor="name">Your Full Name</label>
                  <input type="text" className="form-control _field-wrapper" id="name" name="fullname" placeholder="Type your name" />
                </div>
                <div className="_form_element _x69072156 _full_width form-group form-group-lg col-sm-5">
                  <label className="sr-only _form-label" htmlFor="email">Email<sup>*</sup></label>
                  <input type="email" className="form-control _field-wrapper" id="email" name="email" placeholder="Type your email" required />
                </div>
                <div className="col-sm-2 _button-wrapper _full_width">
                  <button type="submit" className="btn btn-block btn-primary btn-lg _submit" id="_form_1_submit">Sign Up</button>
                </div>

                <div className="_clear-element"></div>

              </div>
              {/*<div className="col-sm-2">
                <button type="submit" className="btn btn-block btn-default btn-lg">Sign Up</button>
              </div>*/}

            </form>
          </div>
        </section>
      )
    }, query)}
  />
);

export default Signup
