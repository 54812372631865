import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { withPreview } from '@prismicio/gatsby-source-prismic-graphql';
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '../utils/linkResolver';
import naaLogo from '../images/naa_logo_full_banner_468x60.png';
import juniperLogo from '../images/juniper-logo.png';
import get from 'lodash/get'

const query = graphql`
  query {
    prismic {
      allNavigations {
        edges {
          node {
            _meta {
              uid
              tags
            }
            body {
              ... on PRISMIC_NavigationBodySocial_network {
                fields {
                  social_network_url {
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                  social_network
                }
              }
            }
            company_display_name
            company_address
            company_phone
            secondary_footer_links {
              link_label
              link {
                _linkType
                ... on PRISMIC_Content_page {
                  _meta {
                    uid
                    type
                  }
                }
                ... on PRISMIC_Contact_page {
                  _meta {
                    uid
                    type
                  }
                }
                ... on PRISMIC_Payment_page {
                  _meta {
                    uid
                    type
                  }
                }
                ... on PRISMIC__ExternalLink {
                  url
                }
                ... on PRISMIC__FileLink {
                  url
                }
              }
            }
            footer_links {
              link_label
              link {
                _linkType
                ... on PRISMIC_Content_page {
                  _meta {
                    uid
                    type
                  }
                }
                ... on PRISMIC_Contact_page {
                  _meta {
                    uid
                    type
                  }
                }
                ... on PRISMIC_Payment_page {
                  _meta {
                    uid
                    type
                  }
                }
                ... on PRISMIC__ExternalLink {
                  url
                }
                ... on PRISMIC__FileLink {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;


export const Footer = () => (
  <StaticQuery
    query={query}
    render={withPreview(data => {
      const footerData = data.prismic.allNavigations.edges[0].node
      return (
        <footer className='footer' role='contentinfo'>
          <div className='container-fluid'>
            <div className='row block-md'>
              <div className='col-xs-12 col-sm-6 col-md-3'>
                <h4 className='pt-2'>{footerData.company_display_name} &copy; {new Date().getFullYear()}</h4>
                {RichText.render(footerData.company_address, linkResolver)}
                {footerData.company_phone && (
                  <p className=''>T: <a href={`tel:${footerData.company_phone}`}>{footerData.company_phone}</a></p>
                )}
              </div>
              <div className='col-xs-12 col-sm-6 col-md-3'>
                <h4 className='pt-2 brand-font'>Links</h4>
                <ul className='list-unstyled'>
                  {footerData.footer_links.map((slice, index) => {
                    var link = get(slice.link, 'url',(linkResolver(get(slice.link, '_meta',[]),[])))
                    return (
                      <li className='' key={`footer-${index}`}>
                        {get(slice.link, 'url') ? (
                          <a href={link}>{get(slice, 'link_label')}</a>
                        ):(
                          <>
                            {link !== '/null' ? (
                              <Link to={link}>{get(slice, 'link_label')}</Link>
                            ):(
                              <Link to={slice.link._meta.type.replace('_page','')}>{get(slice, 'link_label')}</Link>
                            )}
                          </>
                        )}
                      </li>
                    )
                  })}
                </ul>
              </div>
              <div className='col-md-3 col-xs-12'>
                <h4 className='pt-2 brand-font'>Part Of</h4>
                <img className='associations-image img-responsive' src={naaLogo} alt='National Auctioneer Association' loading='lazy' />
              </div>
              <div className='col-md-3 col-xs-12 text-right-sm'>
                <h4 className='pt-2 brand-font'>Social</h4>
                <div className='row d-flex social'>
                  {footerData.body[0].fields.map((social, index) => {
                    return (
                      <a key={`social-${index}`} href={social.social_network_url.url} target='_blank' rel='noopener noreferrer' className='social-icon'><img src={require(`../images/${social.social_network.toLowerCase()}.png`)} alt={social.social_network} className={`img-responsive social-${social.social_network}`} loading='lazy' /></a>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className='row block-md secondary text-center'>
              <ul className='list-unstyled list-inline col-sm-8 text-left-sm'>
                {footerData.secondary_footer_links.map((slice, index) => {
                  var secondaryLink = get(slice.link, 'url',(linkResolver(get(slice.link, '_meta',[]),[])))
                  return (
                    <React.Fragment key={`secondaryFooter-${index}`}>
                      {secondaryLink !== '/undefined' && (
                        <li>
                          {get(slice.link, 'url') ? (
                            <a className='text-muted' href={secondaryLink}>{get(slice, 'link_label')}</a>
                          ):(
                            <>
                              {secondaryLink !== '/null' ? (
                                <Link className='text-muted' to={secondaryLink}>{get(slice, 'link_label')}</Link>
                              ):(
                                <Link className='text-muted' to={slice.link._meta.type.replace('_page','')}>{get(slice, 'link_label')}</Link>
                              )}
                            </>
                          )}
                        </li>
                      )}
                    </React.Fragment>
                  )
                })}
              </ul>
              <div className='col-sm-4 text-right-sm'>
                <a className='juniper' target='_blank' rel='noopener noreferrer' href='https://www.thejuniperstudio.com/'>
                  <img src={juniperLogo}  alt='The Juniper Studio Web Agency' loading='lazy' />
                  <div className='small text-muted'>Crafted by Juniper Studio &copy; {new Date().getFullYear()}</div></a>
                </div>
            </div>
          </div>
        </footer>
      )
    }, query)}
  />
);

export default Footer
